@import "lib/responsive";
@import "lib/settings";
@import "components/navigation";
@import "components/footer";
@import "components/buttons";
@import "components/testimonials.scss";
@import "components/accordion.scss";
@import "components/banners.scss";
@import "components/bannerSlider.scss";
@import "components/latestDeals.scss";
@import "components/benefits.scss";
@import "components/downloadCta.scss";
@import "components/packages.scss";
@import "components/subscribeModal.scss";
@import "components/supportCta.scss";
@import "components/cookies.scss";
@import "components/melonSteps.scss";
@import "components/contentImage.scss";
@import "components/blog.scss";
@import "components/animations.scss";
@import "components/ownPlan.scss";
@import "components/headingAnimations.scss";

// ***********
// PAGE LOADER
// ***********
.ai-page-loader-wrapper {
    min-width: 100%;
    min-height: 40vh;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.ai-inline-loader-content-wrapper {
    margin: 2rem 0 0 0;
    font-family: $ai-fnt-poppins;

    h5 {
        font-family: $ai-fnt-mont;
        font-size: 2rem;
        font-weight: 600;
    }

    p {
        color: $ai-dark-grey;
    }
}
.ai-inline-loader {
    width: 60px;
    height: 60px;
    border: 7px solid rgba(#12963A, 0.2);
    border-bottom-color: #85C137;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin: 0 auto;
    display: flex;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 


main {
    min-height: 50vh;
    @media(min-width: 1200px) {
        margin-bottom: 365px;
        position: relative;
        background-color: #fff;
        z-index: 0;
    }
    padding-top: 142px;
    @media(max-width: 991px) { padding-top: 104px; }
    @media(max-width: 767px) { padding-top: 130px; }

    // &.scrolled {
    //     padding-top: 90px;
    // }
}

.category-nav {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-around;
    padding-top: 80px;
    padding-bottom: 80px;

    button {
        background-color: transparent;
        border: none;
        padding: 20px 25px;
        border-radius: 10px;
        transition: all .3s;

        &.active, &:hover {
            background-color: $ai-red;
            color: $ai-white;
            cursor: pointer;
        }
    }
}

.centered-text {
    padding-bottom: 7rem;
    .col-12 {
        text-align: center;
        @media(min-width: 992px) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    img {
        margin-bottom: 40px;
    }

    .links {
        display: flex;
        justify-content: center;
        gap: 30px;
    }

    p {
        margin-bottom: 2.5rem;

        strong {
            font-weight: 700;
        }
    }
}

.video-banner {
    padding-bottom: 3rem;
    img {
        height: auto;
    }
}

.ai-column-text {
    padding-bottom: 5rem;
    padding-left: 50px;
    padding-right: 50px;
    h2 {
        margin-bottom: 5rem;
    }

    p {
        font-size: 16px;
        @media(max-width: 991px) { font-size: 14px; }
    }

    h4 {
        font-weight: 600;
        margin-bottom: 2rem;
        font-size: 26px;
    }

    img {
        @media(max-width: 991px) { width: 100%; }
    }

    .col-md-6 {
        @media(max-width: 991px) { padding-bottom: 30px; }
    }
}

.ai-intro-section {
    padding-bottom: 6rem;

    img {
        margin-bottom: 30px;
        width: 350px;
    }
}

.ai-large-image-banner {
    padding-bottom: 5rem;
}

.with-icon {
    .image {
        text-align: center !important;
        @media(max-width: 767px) {
            padding-left: 70px !important;
            padding-right: 70px !important;
        }
        img {
            width: 390px;
        }
    }

    .image-right {
        .image {
            text-align: right;
        }
    }
}

.share-wrapper {
    position: sticky;
    top: 150px;
    z-index: 1;
    .icons {
        a {
            display: inline-block;
            margin-right: 10px;
            transition: $transition;
            border-radius: 50%;
            text-align: center;
            position: relative;

            &::after {
                pointer-events: none;
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                content: '';
                box-sizing: content-box;
                box-shadow: 0 0 0 3px #f6637e;
                top: 0;
                left: 0;
                opacity: 0;
                transition: 300ms;
            }

            &:hover {
                &::after {
                    opacity: 1;
                    transform: scale(1.15);
                }
            }

            img {
                width: 30px;
            }
        }
    }
    .links {
        position: absolute;
        right: 0;
        z-index: 1;

        button {
            padding: 0;
            background-color: transparent;
            border: none;
        }
        a, button {
            display: block;
            margin-bottom: 30px;
            transition: $transition;
            border-radius: 50%;
            text-align: center;
            position: relative;
            padding: .1rem;

            &::after {
                pointer-events: none;
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                content: '';
                box-sizing: content-box;
                box-shadow: 0 0 0 3px #f6637e;
                top: 0;
                left: 0;
                opacity: 0;
                transition: 300ms;
            }

            &:hover {
                &::after {
                    opacity: 1;
                    transform: scale(1.15);
                }
            }

            svg {
                font-size: 25px;
                color: #f6637e;
            }
        }
    }
}

//Page Specific
.home {
    .centered-text {
        padding-top: 3rem;
        background-image: url('../images/Melon-Mobile-Green-Graphic-Background.svg');
        background-position: left top;
        background-size: contain;
        background-repeat: no-repeat;

        .lottie-image {
            >div {
                max-width: 100% !important;
            }
        }
    }

    .own-plan, .packages-list {
        padding-bottom: 4rem;
    }
}

.help-center {
    .container {
        @media(min-width: 1200px) {
            padding-left: 100px;
            padding-right: 100px;
        }
        @media(min-width: 1300px) {
            padding-left: 110px;
            padding-right: 110px;
        }
    }
}

.with-store-logo{
    position: relative;
    .stores{
        padding: 0px;
        img{
            width: auto !important;
        }
    }
}
.free-sim-promo {
    .banner {
        h2 {
            padding: 0 240px;
            @media(max-width: 991px) {
                padding: 0 170px; 
            }
            @media(max-width: 767px) {
                padding: 0; 
            }
        }
    }

    .content-image {
        .image {
            img {
                width: 400px;
            }
        }
    }

    .image-left {
        h2 {
            padding-right: 0;
        }
    }

    .image-right {
        h2 {
            padding-right: 100px;
        }
    }

    .divider {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 70px;
        padding-bottom: 6rem;

        .line {
            height: 11px;
            background-color: $border-green;
            border-radius: 20px;
            width: 25.5%;
        }

        span {
            font-weight: 700;
            font-size: 44px;
            font-family: $ai-fnt-mont;
        }
    }
}

.search-results {

    .result {
        h2 {
            font-family: $ai-fnt-poppins;
            font-weight: 700;
            font-size: 1.75rem;
        }
    }

    .no-results {
        h2 {
            font-family: $ai-fnt-poppins;
            font-weight: 700;
            font-size: 1.75rem;
        }
    }

    p, h3 {
        margin-bottom: 2rem;
    }

    .result {
        margin-bottom: 5.3rem;
    }

    p {
        color: $ai-dark-grey;
        font-size: 16px;
    }

    .container {
        @media(min-width: 1200px) {
            padding-left: 100px;
            padding-right: 100px;
        }
    }

    .no-results {
        padding-bottom: 7rem;
        padding-top: 5rem;
        h2 {
            color: $ai-red
        }
    }
}

.legal-content {
    h3 {
        font-weight: 700;
        margin-bottom: 2.8rem;
    }

    .row {
        padding-bottom: 2.8rem;
    }

    strong {
        font-weight: 700;
    }

    div {
        font-family: $ai-fnt-poppins;
        font-size: 19px;
        font-weight: 300;
        @media(max-width: 991px) { font-size: 14px; }
    }
}

.who-we-are {
    .centered-text img {
        margin-bottom: 5px;
        width: 153px;
    }

    .banner {
        margin-bottom: 5rem;
    }

    .video-banner {
        padding-bottom: 5rem;
    }
}

.why-use-melon {
    .image-left {
        img {
           width: 350px; 
        }

        .image {
            text-align: center;
        }
    }

    .image-right {
        h2 {
            padding-right: 150px;
            @media(max-width: 991px) { padding-right: 0; }
        }
    }

    .ai-column-text {
        padding-bottom: 6rem;

        .col-md-6 {
            @media(min-width: 1300px) {
                padding-left: 25px;
                padding-right: 25px;
            }
        }
    }
}

.how-it-works {
    .content-image {
        .image {
            text-align: center;
            @media(max-width: 767px) {
                padding-right: 70px;
            }
            img {
                width: 312px;
            }
        }

        h2 {
            padding-right: 0;
            padding-left: 0;
            font-size: 43px;
            @media(max-width: 1400px) { font-size: clamp(2.1rem, 0.8333rem + 2.309vw, 4rem) }
        }
    }

    .video-banner {
        padding-bottom: 7rem;
    }

    .ai-py-4 {
        padding-top: 2rem;
        padding-bottom: 5rem;
    }
}

.download-app {
    .banner {
        margin-bottom: 3rem;
    }

    .ai-large-image-banner {
        a {
            img {
                width: 158px;
            }
        }
    }

    .with-icon {
        .image {
            text-align: center;
        }
        img {
            width: 390px;
        }
    }
}

.packages {
    .banner {
        padding-left: 150px;
        padding-right: 150px;
        @media(max-width: 991px) {
            padding-left: 80px;
            padding-right: 80px;
        }
        @media(max-width: 767px) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}


.rewards {
    .banner {
        padding-left: 280px;
        padding-right: 280px;
        @media(max-width: 991px) {
            padding-left: 80px;
            padding-right: 80px;
        }
        @media(max-width: 767px) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .image-left {
        h2 {
            padding-right: 50px;
        }
    }

    .centered-text {
        padding-bottom: 4.5rem;
    }
}

.deals {
    .ai-large-image-banner {
        padding-bottom: 3.5rem;
        h2 {
            margin-bottom: 6.5rem;
        }

        img {
            margin-bottom: 7rem;
            border-radius: 60px;
        }
    }

    .ai-column-text {
        img {
            width: 155px;
            object-fit: contain;
            height: 195px;
        }

        h4 {
            font-size: 28px;
            font-family: $ai-fnt-mont;
        }

        p {
            font-size: 14px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .melon-steps {
        h4 {
            font-size: 28px;
            margin-bottom: 30px;
            font-weight: 700;
            font-family: $ai-fnt-mont;
        }

        img {
            width: 109px;
            height: 130px;
        }
    }
}

.no-search-results {
    .banner {
        margin-bottom: 3rem;
    }
}

// Self Help page
.self-help{
    .banner{
        h2{
            padding-inline: none;
            @media screen and (min-width: 1200px) {
                padding-inline: 3rem;
            }
            @media screen and (max-width: 1199px) and (min-width: 770px) {
                padding-inline: 12rem;
            }
            @media screen and (max-width: 769px) and (min-width: 541px) {
                padding-inline: 3rem;
            }
            @media screen and (max-width: 540px) {
                padding-inline: none;
            }
        }
    }
    .title-container {
        h2{
            margin-bottom: 1rem;
        }
        p{
            padding-inline: 6rem;
            @media screen and (max-width: 991px) {
                padding-inline: 0px;
            }
        }
    }
    p{
        font-size: 19px;
    }
    .before-starting{
        & > div{
            padding-block: 1.5rem;
            padding-inline: 9rem;
            background-color: $ai-dark-pink;
            border-radius: 30px;
            color: $ai-white;
            p{
                strong{
                    font-weight: 500;
                }
                margin-bottom: 0px;
                font-size: 19px;
                font-weight: 300;
                padding-inline: 0px;
            }

            @media screen and (min-width:1200px) and (max-width:1399px){
                padding-inline: 7rem;
            }
            @media screen and (max-width:1199px){
                padding-inline: 3rem;
            }
        }
    }
    .list-column{
        h3{
            margin-bottom: 2rem;
            font-weight: 600;
        }
        .d-flex{
            flex-wrap: wrap;
            justify-content: center;
            .list-item{
                width: 330px;
                text-align: center;
                @media screen and (max-width: 991px) {
                    width: auto;
                }
            }
        }
        ul{
            padding-left: 0px;
            li{
                list-style: none;
                display: flex;
                gap: 20px;
                padding: 5px;
                font-size: 19px;
                margin-bottom: .3rem;
                padding-right: 2rem;
                font-weight: 300;

                h5{
                    width: 30px;
                    min-width: 30px;
                    height: 30px;
                    background-color: $border-green;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $ai-white;
                    font-weight: 700;
                    font-family: $ai-fnt-mont;
                    font-size: 19px;
                }

                span{
                    strong{
                        font-weight: bold;
                    }
                }
            }

            @media screen and (max-width: 991px){
                margin-bottom: 3rem;
            }
        }

        &.flex-column{
            ul{
                li{
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 3rem;
                    h5{
                        width: 55px;
                        height: 55px;
                        font-size: 24px;
                        flex: none;
                    }
                    span{
                        flex: none;
                    }
                }
            }
        }
    }

    .note{
        font-size: 19px;
        font-weight: 300;
    }
}

.ai-page-transition-container{
    height: 100vh;
    width: 0%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $ai-green;
    z-index: 9999;
    transform-origin: right;
    //transition: width 0.5s ease-in-out;;
    &.transitioning{
        right: 0;
        animation: loader 1s ease-in-out forwards;
    }
}
@keyframes loader {
    0%{
        width: 0%;
    }
    50%{
        width: 100%;
    }
    100%{
        width: 0;
        right: 0;
        left: auto;
    }
}