//*************************
//Download CTA
//*************************
.download-cta {
    border-radius: 80px;
    padding: 65px 100px;
    position: relative;
    margin-bottom: 2.5rem;
    margin-top: 2.3rem;
    @media(max-width: 991px) {
        border-radius: 15px;
        padding: 65px 20px;
    }

    &.green {
        background: transparent linear-gradient(358deg, #12963A 0%, #D1DC23 100%) 0 0 no-repeat padding-box;
    }

    &.red {
        background: $cta-red;
        border-radius: 20px;
        padding: 95px 100px;
        margin-bottom: 9rem;
        margin-top: 0;
        @media(min-width: 1200px) { margin-bottom: 13rem; }
        @media(max-width: 991px) {
            border-radius: 15px;
            padding: 30px 40px;
        }
    }

    .cta-image {
        >div {
            position: absolute;
            right: -7%;
            width: 62% !important;
            height: 133% !important;
            bottom: -15.5%;
            @media(max-width: 1199px) {
                right: -4%;
                width: 62% !important;
                height: 113% !important;
                bottom: -23.3%;
            }
        }
        .ai-cta-lottie{
            position: absolute;
            right: 0%;
            width: 62% !important;
            height: 133% !important;
            bottom: 0;
            @media(max-width: 1199px) {
                right: -4%;
                width: 62% !important;
                height: 113% !important;
                bottom: -23.3%;
            }
        }
    }

    .main-image {
        position: absolute;
        right: 1%;
        width: 50%;
        bottom: 0;
        @media(max-width: 991px) { display: none; }

        img {
            width: 100%;
            @media(max-width: 767px) { display: none; }
        }
    }

    &.red {
        .main-image {
            bottom: -120px;
            @media(max-width: 1199px) {
                bottom: 42px;
            }
        }
    }

    h2, p {
        color: $ai-white;
    }

    h2 {
        font-weight: 700;
        font-size: 50px;
        @media(max-width: 991px) { font-size: 40px; }
    }

    p {
        font-size: 18px;
        margin-bottom: 30px;
    }

    .row {
        padding-top: 0;
        padding-bottom: 0;
    }

    .text {
        padding-right: 20px;

        img {
            width: 168px;
            @media(max-width: 767px) { width: 110px; }
            &:first-child {
                margin-right: 15px;
            }
        }

        a {
            transition: $transition;
            &:first-child {
                img {
                    width: 157px;
                    @media(max-width: 767px) { width: 100px; }
                }
            }

            &:hover {
                opacity: .8;
            }
        }
    }

    .stores {
        @media(max-width: 991px) { 
            display: flex; 
            justify-content: center;
            align-items: center;
            gap: 30px;
        }
        @media(max-width: 767px) { display: block; }
        a {
            @media(max-width: 991px) {
                display: flex !important;

                img {
                    width: 100% !important;
                    margin-left: auto !important;
                    margin-right: auto !important;
                }
            }
            @media(max-width: 767px) {
                img {
                    width: 45% !important;
                }
            }
        }
    }
}