//********************************
//Benefits
//********************************
.benefits {
    position: relative;
    padding-top: 8rem;
    overflow: hidden;
    padding-bottom: 7rem;
    >.bg {
        background: transparent linear-gradient(180deg, #12963A 0%, #D1DC23 100%) 0% 0% no-repeat padding-box;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 70%;
        width: 130%;
        border-radius: 0 0 100% 100%;
        @media(max-width: 767px) { width: 300%; }
    }

    h2 {
        color: $ai-white;
        margin-bottom: 7rem;
    }

    .container {
        position: relative;
    }

    .benefit {
        background-color: $ai-white;
        padding: 20px 30px;
        box-shadow: 0px 20px 30px #0000000D;
        border-radius: 50px;
        text-align: center;
        @media(max-width: 767px) { border-radius: 15px; }

        img {
            width: 120px;
        }

        h4 {
            margin-bottom: 20px;
            font-size: 26px;
            font-family: $ai-fnt-mont;
            font-weight: 600;
        }

        p {
            font-size: 17px;
        }
    }

    .row {
        padding-top: 0;
        padding-bottom: 0;
    }

    .col-md-6 {
        display: flex;
    }
}

.more-benefits {
    padding-bottom: 4rem;

    h2 {
        margin-bottom: 5rem;
    }

    .col-lg-3 {
        display: flex;
        padding-left: 5px;
        padding-right: 5px;
    }
    .item {
        border: 3px solid $border-green;
        border-radius: 50px;
        padding: 18px 20px 15px 20px;
        text-align: center;
        margin-bottom: 35px;
        @media(max-width: 767px) { border-radius: 15px; }

        img {
            width: 159px;
            object-fit: contain;
            height: 140px;
            @media(max-width: 991px) {
                width: 130px;
                height: 100px;
            }
        }

        p {
            font-size: 17px;
            font-family: $ai-fnt-poppins;
            @media(max-width: 991px) { font-size: 14px }
        }
    }

    h3 {
        font-weight: 600;
        font-family: $ai-fnt-mont;
        margin-bottom: 20px;
        @media(max-width: 991px) { font-size: 20px }
    }
}