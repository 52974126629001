.sliders {
    background-color: $ai-light-grey;
    border-radius: 20px;
}
.range-container {
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;

    .slider {
        //-webkit-appearance: none; /* remove default styling */
        width: 100%;
        height: 10px;
        background: linear-gradient(to right, $ai-red 0%, $ai-red 50%, #fff 50%, #fff 100%);
        outline: none; /* remove focus ring */
        -webkit-transition: .2s; /* smooth transition */
        transition: .2s;
    }
  
    .slider::-webkit-slider-thumb {
        -webkit-appearance: none; /* remove default styling */
        appearance: none;
        width: 15px;
        height: 15px;
        background: $ai-red;
        border-radius: 50%;
        cursor: pointer;
    }

    .slider::-moz-range-thumb {
        width: 25px;
        height: 25px;
        background: #fff;
        border-radius: 50%;
        cursor: pointer;
    }

    .range-value {
        margin-left: 10px;
    }

    label {
        font-weight: 700;
    }
}

.own-plan {
    padding-bottom: 7rem;
    .rc-slider-track {
        background-color: $range-input-red;
        height: 10px;
    }

    .rc-slider-handle {
        border: 3px solid $range-input-red;
        background-color: $range-input-red;
        opacity: 1;
        width: 20px;
        height: 20px;
        &.rc-slider-handle-dragging, &:hover {
            border-color: $range-input-red !important;
            box-shadow: none !important;
        }
    }

    .rc-slider-rail {
        height: 10px;
        background-color: rgba($range-input-red, .5);
    }

    .sliders {
        background-color: $sliders-grey;
        border-radius: 20px;
        padding: 20px;

        .head {
            padding-bottom: 10px;
            .head-top-row{
                display: flex;
                flex-direction: row;
                gap: 30px;
                .icon {
                    width: 35px;
                    height: 35px;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 100%;
                    }
                }
                h4 {
                    font-family: $ai-fnt-mont;
                    font-weight: 700;
                    font-size: 22px;
                }
            }
            .head-bottom-row{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-content: center;
                width: 100%;
                padding-top: 15px;
                .current-value{
                    font-family: $ai-fnt-poppins;
                    font-weight: 700;
                    font-size: 22px;
                    color: $ai-dark-green;
                }
                .current-price {
                    font-family: $ai-fnt-poppins;
                    font-weight: 700;
                    font-size: 16px;
                }
            }


        }



        .labels {
            padding-bottom: 15px;
            border-bottom: 1px solid rgba($slider-labels-border, .4);
            margin-bottom: 20px;
            font-family: $ai-fnt-poppins;
            color: $slider-labels-border;
            user-select: none;

            > div{
                padding-top: 20px;
                position: relative;
                &::before {
                    content: '';
                    background-color: $range-input-red;
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    position: absolute;
                    top: -6px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }

        .data-slider {
            .icon {
                background-color: $ai-dark-green;
            }
        }

        .minutes-slider {
            .icon {
                background-color: $range-input-red;
            }
        }

        .sms-slider {
            .labels {
                margin-bottom: 0;
                border-bottom: none;
            }

            .icon {
                background-color: $ai-light-blue;
            }
        }
    }

    .col-lg-5 {
        display: flex;
        align-items: center;
    }

    .col-lg-7 {
        @media(max-width: 991px) { margin-bottom: 30px; }
    }

    .deal {
        border: 2px solid $ai-green;
        border-radius: 25px;
        overflow: hidden;
        width: 100%;
        position: relative;
        padding-bottom: 100px;
        height: fit-content;
        transform: scale(.9);
        @media(max-width: 767px) { border-radius: 15px; }

        .header {
            background: transparent linear-gradient(1deg, #85C137 0%, #12963A 100%) 0% 0% no-repeat padding-box;
            text-align: center;
            color: $ai-white;
            padding: 20px 15px;
            margin-bottom: 20px;
            padding-bottom: 10px;

            h2 {
                margin-bottom: 0;
                font-size: 24px;
            }
        }

        .icon {
            text-align: center;
            padding: 20px;
            background-color: $range-input-red;
            width: 150px;
            height: 150px;
            border-radius: 50%;
            margin: 0 auto;
            display: flex;
            justify-self: flex-end;
            align-items: flex-end;
            margin-bottom: 25px;

            img {
                width: 100%;
                position: relative;
                top: 6px;
                left: 6px;;
            }
        }

        .price {
            text-align: center;

            &.icon{
                align-items: center;
                justify-content: center;
                position: relative;
                margin-top: 3rem;

                &::after{
                    position: absolute;
                    content: '';
                    width: 170px;
                    height: 170px;
                    border: 5px solid $ai-green;
                    border-radius: 50%;
                }

                p {
                    font-size: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 0;
                    flex-direction: column;
                    color: $ai-white;
                }
    
                strong {
                    font-size: 40px;
                    font-family: $ai-fnt-mont;
                    font-weight: 700;
                }

                &.ai-price-amount-value {
                    p {
                        strong {
                            font-size: 22px;
                        }
                    }
                }
            }
        }

        .details {
            width: fit-content;
            margin: 0 auto;
            
            p {
                font-size: 26px;
                margin-bottom: 0;
            }

            strong {
                display: inline-block;
                margin-right: 12px;
            }

            img {
                width: 55px;
            }
        }

        .link {
            text-align: center;
            padding: 25px;
            position: absolute;
            transform: translateX(-50%);
            left: 50%;
            width: 90%;
            bottom: 10px;

            .btn {
                width: 100%;
            }
        }
    }
}