.content-image {
    padding-bottom: 7rem;
    @media(max-width: 991px) {
        padding-left: 15px;
        padding-right: 15px;
    }

    @media screen and (max-width: 767px) {
        .lottie-image{
            & > div{
                width: auto !important;
                height: auto !important;
            }
        }
    }
    
    .image {
        img {
            width: 100%;
            height: auto;
            border-radius: 50px;
            @media(max-width: 991px) { width: 100% !important }
            @media(max-width: 767px) { border-radius: 15px; }
        }
    }

    .row {
        align-items: center;
    }

    .image {
        @media(max-width: 767px) {
            margin-bottom: 30px;
        }
    }

    .image-left {
        .image {
            padding-right: 30px;
            @media(max-width: 767px) {
                padding-right: 15px;
            }
        }

        >.text {
            padding-left: 30px;
            @media(max-width: 767px) {
                padding-left: 15px;
            }

            p {
                @media(min-width: 1200px) { padding-right: 70px; }
            }
        }

        h2 {
            padding-right: 100px;
            @media(max-width: 991px) { padding-right: 0 !important; }
        }
    }

    .image-right {
        .image {
            padding-left: 30px;
            @media(max-width: 767px) {
                order: 1;
                padding-left: 15px;
            }
        }

        >.text {
            padding-right: 30px;
            @media(max-width: 767px) {
                order: 2;
                padding-right: 15px;
            }
        }
    }

    h2 {
        margin-bottom: 30px;
    }

    p {
        margin-bottom: 30px;
        font-family: $ai-fnt-poppins;
        font-size: 17px;
        line-height: 1.8;
        @media(max-width: 991px) { font-size: 14px; }
    }

    li {
        font-family: $ai-fnt-poppins;
        font-size: 17px;
        line-height: 1.9;
        position: relative;
        @media(max-width: 991px) { font-size: 14px; }
        &::before {
            position: absolute;
            content: '';
            width: 10px;
            height: 10px;
            background-color: $ai-black;
            border-radius: 50%;
            left: -22px;
            top: 50%;
            transform: translateY(-50%);
        }
    }


    ul {
        margin-bottom: 2.5rem;
        padding-left: 22px;
        list-style: none;
    }

    h5 {
        width: 55px;
        height: 55px;
        background-color: $border-green;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $ai-white;
        font-weight: 700;
        font-family: $ai-fnt-mont;
        font-size: 24px;
        margin-bottom: 2.5rem;
    }

    .list-item {
        display: flex;
        gap: 20px;
        padding: 5px;
        align-items: center;
        img {
            width: 30px;
        }

        strong {
            font-size: 18px;
        }
    }

    .stores {
        display: flex;
        gap: 70px;
        padding: 5px;
        align-items: center;
        @media(max-width: 767px) { flex-wrap: wrap; }
        img {
            &:nth-child(1) {
                width: 65px;
            }
            &:nth-child(2), &:nth-child(3) {
                width: 160px;
            }
        }
    }

    .image-reveal {
        border-radius: 20px;
        @media(max-width: 767px) { border-radius: 15px; }
    }
}

.how-it-works {
    .content-image {
        img {
            max-width: 400px;
        }
    }
}

