//****************************
//Navigation
//****************************
.info-bar {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba($ai-dark-grey, .4);
    @media(max-width: 767px) { padding: 35px 15px; }

    small {
        font-size: 14px;
        font-weight: 300;
        a {
            color: $ai-black;
            font-size: 14px;
            &:hover {
                color: $ai-red;
            }
        }
    }
}

.promo-sticker {
    position: fixed;
    background-color: $ai-red;
    color: $ai-white;
    border-radius: 0 0 10px 10px;
    padding: 15px 30px;
    font-size: 18px;
    text-decoration: none;
    font-weight: 400;
    font-family: $ai-fnt-mont;
    transform: rotate(90deg);
    top: 50%;
    right: -90px;
    z-index: 11;
    transition: all .3s;
    overflow: hidden;
    border: 2px solid $ai-red;

    @media(max-width: 767px) {
        top: 65%;
    }
    @media(max-width: 400px) {
        top: 60%;
    }
    &:hover {
        color: $ai-red;
    }
    .bg{
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        display: block;
        background-color: $ai-white;
        transform: translateX(-50%), translateY(-50%);
        z-index: -1;
        pointer-events: none;
    }
}

.search-bar {
    background-color: $ai-white;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 111;
    padding: 33px 0;
    transition: all .5s;
    transform: translateY(-100%);

    &.show {
        transform: translateY(0);
    }

    input {
        width: 100%;
        border-radius: 10px;
        border: 3px solid $ai-green;
        padding: 24px 15px;
        font-size: 16px;
    }

    .container {
        display: flex;
        align-items: center;
    }

    .input {
        position: relative;
        width: 93%;

        button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 20px;
        }
    }

    .close {
        margin-left: auto;
        margin-right: 0;
        button {
            background-color: transparent;
            border: none;
            width: 50px;
            height: 50px;

            img {
                width: 10px;
            }

            span {
                display: inline-block;
                margin-left: 12px;
                font-size: 16px;
            }
        }
    }
}

header {
    border-bottom: 1px solid $ai-light-grey;
    background-color: $ai-white;
}

.nav-wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 11;
    background-color: $ai-white;
    transition: $transition;

    &.scrolled {
        top: -44px;
        @media(max-width: 767px) { top: -70px; }
    }
}

.navigation {
    background-color: $ai-white;
    z-index: 1;
    width: 100%;
    transition: $transition;
    @media(max-width: 767px) { padding: 5px 0; }

    .navbar-toggler {
        height: 30px;
        width: 30px;
        cursor: pointer;
        border: none;
        position: relative;
        padding: 0;
        &:focus {
            outline: none;
            box-shadow: none;
        }

        span, span::before, span::after {
            background: $border-green;
            border-radius: 3px;
            content: '';
            position: absolute;
            width: 30px;
            height: 4px;         
            margin-top: -2px; 
            -webkit-transition: .3s ease-in-out;
            -moz-transition: .3s ease-in-out;
            -o-transition: .3s ease-in-out;
            transition: .3s ease-in-out;
            left: 0;
        }

        span {
            &::before {
                margin-top:-12px;
            }

            &::after {
                margin-top:12px;
            }
        }

        &.open {
            span {
                background: transparent;

                &::before {
                    margin-top: 0;
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                }

                &::after {
                    margin-top: 0;
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }
            }
        }
    }
}

.navbar-brand {
    display: inline-block;
    width: 162px;
    @media(max-width: 1199px) {
        width: 130px;
    }

    img {
        width: 100%;
    }
}
.navbar {
    padding-top: 0;
    padding-bottom: 0;

    .navbar-nav {
        align-items: center;
        @media(max-width: 991px) {
            padding-top: 30px;
            flex-direction: row;
            flex-wrap: wrap
        }
        .nav-link {
            padding-right: 5px;
            padding-left: 5px;
            font-size: 15px;
            font-weight: 400;
            color: $ai-black;
            position: relative;
            padding-bottom: 37px;
            padding-top: 37px;
            @media(max-width: 991px) { 
                color: $border-green;
                font-weight: 600;
                font-size: 20px;
                padding: 10px 20px;
            }
            &::before {
                content: '';
                width: 0;
                height: 5px;
                background-color: $ai-red;
                bottom: -1px;
                position: absolute;
                transition: $transition;
                left: 0;
            }
            &.active, &:hover {
                color: $range-input-red;
                @media(max-width: 991px) {
                    color: $border-green;
                    background-color: rgba(#D1DC23, .2);
                    border-radius: 16px;
                }
            }
            &:hover {
                &::before {
                    width: 100%;
                }
            }
            &.dropdown-toggle {
                &::after, &::before {
                    content: none;
                }
            }
        }

        .btn, .login, .search-icon {
            @media(max-width: 991px) {
                margin-bottom: 15px;
                margin-left: 18px;
            }
        }

        .dropdown-menu {
            border-radius: 0 0 25px 25px;
            border-color: $ai-white;
            top: 94px;
            transition: all .5s;
            opacity: 0;
            pointer-events: none;
            display: block;
            left: -36px;
            padding-top: 25px;
            @media(max-width: 991px) {
                opacity: 1 !important;
                padding-top: 10px;
                display: none;
                &.open {
                    display: block;
                }
            }
            &:hover {
                opacity: 1;
                pointer-events: all;
            }

            .dropdown-item {
                padding: 15px 40px;
                font-size: clamp(1rem, 0.8667rem + 0.2431vw, 1.2rem);
                background-color: transparent !important;
                color: $ai-black;
                @media(max-width: 991px) { 
                    color: $border-green;
                    font-size: 18px;
                }
                &:last-child {
                    padding-bottom: 30px;
                    @media(max-width: 991px) { padding-bottom: 0; }
                }
                &:first-child {
                    &::before {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        height: 5px;
                        width: 0;
                        background-color: $range-input-red;
                        top: -2px;
                        left: 18%;
                        transition: $transition;
                        @media(max-width: 991px) { content: none !important; }
                    }
                }
                &:hover, &.active {
                    background-color: transparent;
                    color: $range-input-red;
                }
            }
        }

        .nav-item {
            margin-right: 10px;
            margin-left: 10px;
            padding-top: 0;
            padding-bottom: 0;
            @media(max-width: 991px) {
                width: 100%;
                position: relative;
            }
            &:last-child { margin-right: 0; }
            &:hover {
                .dropdown-item {
                    &:first-child {
                        &::before {
                            width: 50px;
                        }
                    }
                }
            }

            i.arrow {
                position: absolute;
                right: 20px;
                top: 20px;
                display: inline-block;
                background-image: url('../../images/icons/arrow-green.svg');
                transform: rotate(180deg);
                transition: $transition;
                width: 24px;
                height: 24px;
                &.open {
                    transform: rotate(0);
                }
                @media(min-width: 992px) { display: none; }
            }

            .login {
                font-size: 15px;
                font-weight: 400;
                @media(min-width: 1200px) { 
                    margin-left: 24px; 
                    margin-right: 17px;
                }
            }

            .search-icon {
                background-color: transparent;
                border: none;
                transition: $transition;
                .icon {
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    background-size: 100%;
                    transition: $transition;
                    transform: rotate(0);
                    &.icon-search {
                        background-image: url('../../images/search-line.svg');
                    }
                }

                &:hover {
                    .icon {
                        &.icon-search {
                            background-image: url('../../images/search-line-red.svg');
                        }
                    }
                }
            }
            &.dropdown {
                @media(max-width: 991px) { 
                    padding-bottom: 0; 
                    width: 100%;
                }
                &:hover {
                    .dropdown-menu {
                        opacity: 1;
                        pointer-events: all;
                    }
                }
            }
        }
        @media (max-width: 991px) {
            .ai-nav-download-btn{
                display: inline-flex;
                width: fit-content;
            }
            .ai-nav-login{
                display: inline-flex;
                width: fit-content;
            }
        }
    }
}