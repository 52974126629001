/* Bootstrap Overrides
----------------------------------------------- */
:root {
  //Fonts
  --bs-font-sans-serif: 'Montserrat', sans-serif;
  --bs-body-font-family: var($ai-fnt-poppins);
  //Links
  --bs-link-color: #85C137;
  --bs-link-hover-color: #ED174C;
}
.row{
  //padding-top: 4rem;
  //padding-bottom: 4rem;
  .row{
    padding: 0;
  }
}

@media (min-width: 1400px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1220px;
    }
}

/* Colors
----------------------------------------------- */
$ai-white: #FFFFFF;
$ai-black: #000000;
$ai-dark-grey: #6B6D70;
$ai-light-grey: #EDEDED;
$ai-green: #1DC242;
$ai-green-2: #52BF30;
$ai-green-3: #2BC049;
$ai-light-green: #C4E83E;
$ai-light-green-2:#BDE061;
$ai-dark-green: #2B902A;
$ai-dark-green-2: #288C28;
$ai-dark-green-3: #12963A;
$ai-yellow: #D1DC23;
$ai-red: #D60926;
$ai-dark-red: #FC1049;
$ai-maroon: #BC0C3B;
$ai-pink: #FA5078;
$ai-pink-2:#F6637E;
$ai-dark-pink:#F91A4D;
$ai-dark-green: #12963A;
$ai-light-yellow: #D1DC23;
$ai-light-blue: #219ED4;

$border-green: #85C137;
$banner-badge-red: #F91A4D;
$button-green: #2BC049;
$input-border: #1E953F;
$range-input-red: #FC1049;
$sliders-grey: #EDEDED66;
$slider-labels-border: #6B6D70;
$cta-red: #ED174C;

/**
  Backgrounds
 */

 .bg-light {
    background-color: rgba($ai-light-grey, .4) !important;
    padding-top: 7rem;
    margin-bottom: 7rem;
 }
 
/**
  Text helpers
 */
.ai-text-white{
  color: $ai-white;
}

.normal-weight-heading {
    h2 {
        font-weight: 400;
        strong {
            font-weight: 700;
        }
    }
}

/* Fonts
----------------------------------------------- */
$ai-fnt-mont: 'Montserrat', sans-serif;
$ai-fnt-poppins: 'Poppins', sans-serif;

//Font Styles
html {
    font-size: 14px;
    scroll-behavior: auto;
    scroll-padding-top: 200px;
}
body {
    font-family: $ai-fnt-poppins;
    scroll-behavior: auto;
}

h1,
h2,
h3 {
    font-family: $ai-fnt-mont;
}

h1{
    font-size: clamp(2.4rem, 0.6667rem + 3.1597vw, 5rem);
}
h2{
    font-size: clamp(2.1rem, 0.8333rem + 2.3090vw, 4rem);
    margin-bottom: 2.1rem;
    font-weight: 600;
}
h3{}
h4{}
h5{}
h6{}
a{}
p, li{
    font-size: 1.4rem;
    font-size: 19px;
    font-weight: 300;
    @media(max-width: 991px) { font-size: 14px; }
}
p,
a,
li{
    font-family: $ai-fnt-poppins;
}

input, a, button {
    &:focus, &:active {
        outline: none;
        box-shadow: none;
    }
}

//Text Helpers
//Font Family
.ai-text-mont{
  font-family: $ai-fnt-mont;
}
.ai-text-poppins{
  font-family: $ai-fnt-poppins;
}

.reveal-heading {
    overflow: hidden;

    h1, h2, h3, h4 {
        transition: all .5s;
        transform: translateY(-250%);
    }
    &.slide-up {
        h1, h2, h3, h4 {
            transform: translateY(0);
        }
    }
}

//Alignment
.ai-text-center{
  text-align: center;
}

/* Spacings
----------------------------------------------- */
/**
  Paddings
 */
.ai-{
  &p{
    //padding bottom (ai-pt-x)
    &t-0{padding-top: 0;}
    &t-1{padding-top: 1rem;}
    &t-2{padding-top: 2rem;}
    &t-3{padding-top: 3rem;}
    &t-4{padding-top: 4rem;}
    &t-5{padding-top: 5rem;}
    &t-6{padding-top: 6rem;}

    //padding right (ai-pl-x)
    &r-0{padding-right: 0;}
    &r-1{padding-right: 1rem;}
    &r-2{padding-right: 2rem;}
    &r-3{padding-right: 3rem;}
    &r-4{padding-right: 4rem;}
    &r-5{padding-right: 5rem;}
    &r-6{padding-right: 6rem;}

    //padding bottom (ai-pb-x)
    &b-0{padding-bottom: 0;}
    &b-1{padding-bottom: 1rem;}
    &b-2{padding-bottom: 2rem;}
    &b-3{padding-bottom: 3rem;}
    &b-4{padding-bottom: 4rem;}
    &b-5{padding-bottom: 5rem;}
    &b-6{padding-bottom: 6rem;}

    //padding left (ai-pl-x)
    &l-0{padding-left: 0;}
    &l-1{padding-left: 1rem;}
    &l-2{padding-left: 2rem;}
    &l-3{padding-left: 3rem;}
    &l-4{padding-left: 4rem;}
    &l-5{padding-left: 5rem;}
    &l-6{padding-left: 6rem;}

    //padding horizontal (ai-px-x)
    &x-0{padding-left: 0; padding-right: 0;}
    &x-1{padding-left: 1rem; padding-right: 1rem;}
    &x-2{padding-left: 2rem; padding-right: 2rem;}
    &x-3{padding-left: 3rem; padding-right: 3rem;}
    &x-4{padding-left: 4rem; padding-right: 4rem;}
    &x-5{padding-left: 5rem; padding-right: 5rem;}
    &x-6{padding-left: 6rem; padding-right: 6rem;}

    //padding vertical (ai-py-x)
    &y-0{padding-bottom: 0; padding-top: 0;}
    &y-1{padding-bottom: 1rem; padding-top: 1rem;}
    &y-2{padding-bottom: 2rem; padding-top: 2rem;}
    &y-3{padding-bottom: 3rem; padding-top: 3rem;}
    &y-4{padding-bottom: 4rem; padding-top: 4rem;}
    &y-5{padding-bottom: 5rem; padding-top: 5rem;}
    &y-6{padding-bottom: 6rem; padding-top: 6rem;}
  }

  &m{
    //margin top (ai-mt-x)
    &t-0{margin-top: 0;}
    &t-1{margin-top: 1rem;}
    &t-2{margin-top: 2rem;}
    &t-3{margin-top: 3rem;}
    &t-4{margin-top: 4rem;}
    &t-5{margin-top: 5rem;}
    &t-6{margin-top: 6rem;}

    //margin right (ai-ml-x)
    &r-0{margin-right: 0;}
    &r-1{margin-right: 1rem;}
    &r-2{margin-right: 2rem;}
    &r-3{margin-right: 3rem;}
    &r-4{margin-right: 4rem;}
    &r-5{margin-right: 5rem;}
    &r-6{margin-right: 6rem;}

    //margin bottom (ai-mb-x)
    &b-0{margin-bottom: 0;}
    &b-1{margin-bottom: 1rem;}
    &b-2{margin-bottom: 2rem;}
    &b-3{margin-bottom: 3rem;}
    &b-4{margin-bottom: 4rem;}
    &b-5{margin-bottom: 5rem;}
    &b-6{margin-bottom: 6rem;}

    //margin left (ai-ml-x)
    &l-0{margin-left: 0;}
    &l-1{margin-left: 1rem;}
    &l-2{margin-left: 2rem;}
    &l-3{margin-left: 3rem;}
    &l-4{margin-left: 4rem;}
    &l-5{margin-left: 5rem;}
    &l-6{margin-left: 6rem;}

    //margin horizontal (ai-mx-x)
    &x-1{margin-left: 0; margin-right: 0;}
    &x-1{margin-left: 1rem; margin-right: 1rem;}
    &x-2{margin-left: 2rem; margin-right: 2rem;}
    &x-3{margin-left: 3rem; margin-right: 3rem;}
    &x-4{margin-left: 4rem; margin-right: 4rem;}
    &x-5{margin-left: 5rem; margin-right: 5rem;}
    &x-6{margin-left: 6rem; margin-right: 6rem;}

    //margin vertical (ai-my-x)
    &y-0{margin-bottom: 0; margin-top: 0;}
    &y-1{margin-bottom: 1rem; margin-top: 1rem;}
    &y-2{margin-bottom: 2rem; margin-top: 2rem;}
    &y-3{margin-bottom: 3rem; margin-top: 3rem;}
    &y-4{margin-bottom: 4rem; margin-top: 4rem;}
    &y-5{margin-bottom: 5rem; margin-top: 5rem;}
    &y-6{margin-bottom: 6rem; margin-top: 6rem;}
  }
}

.pb-7 {
    padding-bottom: 7rem;
}

$transition: all .3s;


/* Mixins
----------------------------------------------- */